var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12 col-12 mb-4 mt-4 pb-2"
  }, [_c('h5', {
    staticClass: "text-center",
    staticStyle: {
      "text-transform": "uppercase"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("amendment.ownership1")) + " ")]), _c('form', {
    staticClass: "login-form"
  }, [_c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.moveableProperty.property_type.$error
    }
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("pls-select-prop-type")) + ":")]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.moveableProperty.property_type.$model,
      expression: "$v.moveableProperty.property_type.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control custom-select",
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.$v.moveableProperty.property_type, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }, function ($event) {
        return _vm.onChange($event);
      }]
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("-- " + _vm._s(_vm.$t("select-prop-type")) + "a --")]), _c('option', {
    attrs: {
      "value": "Bank"
    }
  }, [_vm._v("Bank")]), _c('option', {
    attrs: {
      "value": "Kenderaan"
    }
  }, [_vm._v(_vm._s(_vm.$t("vehicle")))]), _c('option', {
    attrs: {
      "value": "Saham"
    }
  }, [_vm._v(_vm._s(_vm.$t("shares")))]), _c('option', {
    attrs: {
      "value": "Insurans"
    }
  }, [_vm._v(_vm._s(_vm.$t("insurance")))]), _c('option', {
    attrs: {
      "value": "Simpanan"
    }
  }, [_vm._v(_vm._s(_vm.$t("savings")))]), _c('option', {
    attrs: {
      "value": "Syarikat"
    }
  }, [_vm._v(_vm._s(_vm.$t("company")))])]), _vm.$v.moveableProperty.property_type.$error && !_vm.$v.moveableProperty.property_type.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("pls-select-prop-type")) + ". ")]) : _vm._e()]), _vm.drop.container == 'Bank' ? _c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.moveableProperty.bank_type.$error
    }
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("bank-type")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.moveableProperty.bank_type.$model,
      expression: "$v.moveableProperty.bank_type.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "name": ""
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.$v.moveableProperty.bank_type, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("-- " + _vm._s(_vm.$t("choose.bank-type")) + " --")]), _vm._l(_vm.banks, function (bank, index) {
    return _c('option', {
      key: index,
      domProps: {
        "value": bank.name
      }
    }, [_vm._v(" " + _vm._s(bank.name) + " ")]);
  })], 2), _vm.$v.moveableProperty.bank_type.$error && !_vm.$v.moveableProperty.bank_type.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("pls-select-bank-type")) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.moveableProperty.bank_branch.$error
    }
  }, [_c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.moveableProperty.bank_branch.$model,
      expression: "$v.moveableProperty.bank_branch.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": "Cawangan Bank*"
    },
    domProps: {
      "value": _vm.$v.moveableProperty.bank_branch.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.moveableProperty.bank_branch, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.moveableProperty.bank_branch.$error && !_vm.$v.moveableProperty.bank_branch.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("bank-branches")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.moveableProperty.bank_account_no.$error
    }
  }, [_c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.moveableProperty.bank_account_no.$model,
      expression: "$v.moveableProperty.bank_account_no.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": "No. Akaun*"
    },
    domProps: {
      "value": _vm.$v.moveableProperty.bank_account_no.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.moveableProperty.bank_account_no, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.moveableProperty.bank_account_no.$error && !_vm.$v.moveableProperty.bank_account_no.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("acc-no")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()])])]) : _vm._e(), _vm.drop.container == 'Kenderaan' ? _c('div', {
    staticClass: "card mt-2"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.moveableProperty.transport_type.$error
    }
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("vehicle-type")))]), _vm._v(" "), _c('img', {
    directives: [{
      name: "tippy",
      rawName: "v-tippy"
    }],
    staticClass: "mb-1",
    attrs: {
      "content": "Jenis kenderaan adalah seperti: Kereta, motosikal, lori, van,\n                dan lain-lain",
      "height": "20",
      "src": "/images/icon/info-button.png"
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.moveableProperty.transport_type.$model,
      expression: "$v.moveableProperty.transport_type.$model",
      modifiers: {
        "trim": true
      }
    }, {
      name: "uppercase",
      rawName: "v-uppercase"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": "Jenis Kenderaan*"
    },
    domProps: {
      "value": _vm.$v.moveableProperty.transport_type.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.moveableProperty.transport_type, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.moveableProperty.transport_type.$error && !_vm.$v.moveableProperty.transport_type.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("vehicle-type")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.moveableProperty.transport_manufacturer.$error
    }
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("made-in")) + " ")]), _vm._v(" "), _c('img', {
    directives: [{
      name: "tippy",
      rawName: "v-tippy"
    }],
    staticClass: "mb-1",
    attrs: {
      "content": "Contoh: Proton, Perodua, Honda, Toyota, Yamaha, dan lain-lain",
      "height": "20",
      "src": "/images/icon/info-button.png"
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.moveableProperty.transport_manufacturer.$model,
      expression: "$v.moveableProperty.transport_manufacturer.$model",
      modifiers: {
        "trim": true
      }
    }, {
      name: "uppercase",
      rawName: "v-uppercase"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": "Buatan*"
    },
    domProps: {
      "value": _vm.$v.moveableProperty.transport_manufacturer.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.moveableProperty.transport_manufacturer, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.moveableProperty.transport_manufacturer.$error && !_vm.$v.moveableProperty.transport_manufacturer.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("made-in")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.moveableProperty.transport_model.$error
    }
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("model-name")) + " ")]), _vm._v(" "), _c('img', {
    directives: [{
      name: "tippy",
      rawName: "v-tippy"
    }],
    staticClass: "mb-1",
    attrs: {
      "content": "Contoh: Viva 850, CR-V 2.0L i-VTEC (A), KRISS AN110F dan lain-lain",
      "height": "20",
      "src": "/images/icon/info-button.png"
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.moveableProperty.transport_model.$model,
      expression: "$v.moveableProperty.transport_model.$model",
      modifiers: {
        "trim": true
      }
    }, {
      name: "uppercase",
      rawName: "v-uppercase"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": "Nama Model*"
    },
    domProps: {
      "value": _vm.$v.moveableProperty.transport_model.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.moveableProperty.transport_model, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.moveableProperty.transport_model.$error && !_vm.$v.moveableProperty.transport_model.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("model-name")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.moveableProperty.transport_registration_number.$error
    }
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("org.reg-no")))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.moveableProperty.transport_registration_number.$model,
      expression: "\n                $v.moveableProperty.transport_registration_number.$model\n              ",
      modifiers: {
        "trim": true
      }
    }, {
      name: "uppercase",
      rawName: "v-uppercase"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": "No. Pendaftaran*"
    },
    domProps: {
      "value": _vm.$v.moveableProperty.transport_registration_number.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.moveableProperty.transport_registration_number, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.moveableProperty.transport_registration_number.$error && !_vm.$v.moveableProperty.transport_registration_number.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("org.reg-no")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()])])]) : _vm._e(), _vm.drop.container == 'Saham' ? _c('div', {
    staticClass: "card mt-2"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.moveableProperty.institution_name.$error
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.moveableProperty.institution_name.$model,
      expression: "$v.moveableProperty.institution_name.$model",
      modifiers: {
        "trim": true
      }
    }, {
      name: "uppercase",
      rawName: "v-uppercase"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": "Nama Organisasi*"
    },
    domProps: {
      "value": _vm.$v.moveableProperty.institution_name.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.moveableProperty.institution_name, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.moveableProperty.institution_name.$error && !_vm.$v.moveableProperty.institution_name.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("org-name")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.moveableProperty.account_number.$error
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.moveableProperty.account_number.$model,
      expression: "$v.moveableProperty.account_number.$model",
      modifiers: {
        "trim": true
      }
    }, {
      name: "uppercase",
      rawName: "v-uppercase"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": "No Akaun / No Ahli*"
    },
    domProps: {
      "value": _vm.$v.moveableProperty.account_number.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.moveableProperty.account_number, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.moveableProperty.account_number.$error && !_vm.$v.moveableProperty.account_number.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("accmembership-no")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()])])]) : _vm._e(), _vm.drop.container == 'Insurans' ? _c('div', {
    staticClass: "card mt-2"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.moveableProperty.institution_name.$error
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.moveableProperty.institution_name.$model,
      expression: "$v.moveableProperty.institution_name.$model",
      modifiers: {
        "trim": true
      }
    }, {
      name: "uppercase",
      rawName: "v-uppercase"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": "Nama Organisasi*"
    },
    domProps: {
      "value": _vm.$v.moveableProperty.institution_name.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.moveableProperty.institution_name, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.moveableProperty.institution_name.$error && !_vm.$v.moveableProperty.institution_name.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("org-name")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.moveableProperty.policy_number.$error
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.moveableProperty.policy_number.$model,
      expression: "$v.moveableProperty.policy_number.$model",
      modifiers: {
        "trim": true
      }
    }, {
      name: "uppercase",
      rawName: "v-uppercase"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": "No Polisi*"
    },
    domProps: {
      "value": _vm.$v.moveableProperty.policy_number.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.moveableProperty.policy_number, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.moveableProperty.policy_number.$error && !_vm.$v.moveableProperty.policy_number.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("policy-no")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()])])]) : _vm._e(), _vm.drop.container == 'Simpanan' ? _c('div', {
    staticClass: "card mt-2"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.moveableProperty.institution_name.$error
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.moveableProperty.institution_name.$model,
      expression: "$v.moveableProperty.institution_name.$model",
      modifiers: {
        "trim": true
      }
    }, {
      name: "uppercase",
      rawName: "v-uppercase"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": "Nama Organisasi*"
    },
    domProps: {
      "value": _vm.$v.moveableProperty.institution_name.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.moveableProperty.institution_name, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.moveableProperty.institution_name.$error && !_vm.$v.moveableProperty.institution_name.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("org-name")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.moveableProperty.account_number.$error
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.moveableProperty.account_number.$model,
      expression: "$v.moveableProperty.account_number.$model",
      modifiers: {
        "trim": true
      }
    }, {
      name: "uppercase",
      rawName: "v-uppercase"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": "No Akaun / No Ahli*"
    },
    domProps: {
      "value": _vm.$v.moveableProperty.account_number.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.moveableProperty.account_number, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.moveableProperty.account_number.$error && !_vm.$v.moveableProperty.account_number.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("org-name")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()])])]) : _vm._e(), _vm.drop.container == 'Syarikat' ? _c('div', {
    staticClass: "card mt-2"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.moveableProperty.institution_name.$error
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.moveableProperty.institution_name.$model,
      expression: "$v.moveableProperty.institution_name.$model",
      modifiers: {
        "trim": true
      }
    }, {
      name: "uppercase",
      rawName: "v-uppercase"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": "Nama Organisasi*"
    },
    domProps: {
      "value": _vm.$v.moveableProperty.institution_name.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.moveableProperty.institution_name, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.moveableProperty.institution_name.$error && !_vm.$v.moveableProperty.institution_name.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("org-name")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.moveableProperty.company_registration_number.$error
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.moveableProperty.company_registration_number.$model,
      expression: "\n                $v.moveableProperty.company_registration_number.$model\n              ",
      modifiers: {
        "trim": true
      }
    }, {
      name: "uppercase",
      rawName: "v-uppercase"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": "No Pendaftaran Syarikat*"
    },
    domProps: {
      "value": _vm.$v.moveableProperty.company_registration_number.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.moveableProperty.company_registration_number, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.moveableProperty.company_registration_number.$error && !_vm.$v.moveableProperty.company_registration_number.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("co-regno")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()])])]) : _vm._e(), _c('div', {
    staticClass: "row mt-3"
  }, [_c('div', {
    staticClass: "col-lg-12 text-right"
  }, [_c('div', {
    staticClass: "form-group"
  }, [[0, 1, 4].includes(_vm.amendment_status) ? _c('b-button', {
    staticClass: "btn btn-primary",
    on: {
      "click": function ($event) {
        return _vm.registermoveable();
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("save-next")))]) : _vm._e()], 1)])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }